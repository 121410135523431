import React, { useEffect, useState } from 'react';
import { questionAnswers } from '../../constraints';
import { useMemo } from 'react';
import { Accordion, AccordionItem } from "@nextui-org/react";

const Questions = ({ isShort }) => {

    const [questions, setQuestions] = useState(questionAnswers);

    useEffect(() => {
        if (isShort) {
            setQuestions(questionAnswers.slice(0, 3))
        }
    }, [isShort])

    const renderQuestions = useMemo(() => {

        return (
            <div className='max-w-[980px] mx-auto'>
                <Accordion variant="splitted" fullWidth={true}>
                    {
                        questions.map((item, idx) => (
                            <AccordionItem className='rounded-none mb-2' key={String(idx + 1)} aria-label={item.question} title={item.question}>
                                {item.answer}
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </div>
        );
    }, [questions]);

    return (
        <div className='bg-blue-100  md:p-20 p-10' >
            <div>
                <div className=' '>
                    <h1 className='text-black font-semibold text-4xl pb-10 text-center'>Frequently asked questions</h1>
                    {renderQuestions}
                </div>
            </div>
        </div>
    )
}

export default Questions;