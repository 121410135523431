import React from 'react';
import { About, ClassRoom, Footer} from "../../components"

const About2 = () => {
  return (
    <div>
        <About/>
        <ClassRoom/>
        <Footer/>
    </div>
  )
}

export default About2