import React from 'react';
import { Image } from '@nextui-org/react';
import { icons } from '../../assets';

const ClassRoom = () => {
    return (
        <div className='flex justify-center items-center p-20 bg-primary'>
            <Image
                className='w-[300px]'
                radius='none'
                src={icons.logo2}
                alt='logo-2'
            />
        </div>
    )
}

export default ClassRoom