import React from 'react';
import { icons, images } from '../../assets';
import { Image, } from '@nextui-org/react';

const Contact = () => {
  return (
    <div className='bg-blue-100   flex justify-center p-20 min-h-dvh'>
      <div className='w-default-max-width flex flex-col gap-10  justify-center items-center'>
        <h1 className='text-black text-3xl font-bold '>Contact Us</h1>
        <div className='flex w-full md:justify-center justify-center  flex-wrap gap-20 items-center'>
          <div className='flex flex-col gap-5'>
            <div  className='flex flex-col gap-2'>
              <h4 className='text-gray-700 font-semibold text-xl'>Call</h4>
              <a  href="tel:+919332355074" className='text-black text-lg'>+91 9332355074</a>
            </div>
            {/* <div className='flex flex-col gap-2'>
              <h4 className='text-gray-700 font-semibold text-xl'>WhatsApp</h4>
              <p className='text-black text-lg'>+91 9332355074</p>
            </div> */}
            {/* <div className='flex flex-col gap-2'>
              <h4 className='text-gray-700 font-semibold text-xl'>Email</h4>
              <p className='text-black text-lg'>sahasralearningofficial@gmail.com</p>
            </div> */}

            <div className='flex flex-col gap-2'>
              <h4 className='text-gray-700 font-semibold text-xl'>Follow us on</h4>
              <div className='flex gap-4 items-center mt-4'>
                <a
                  href='https://www.instagram.com/sahasraeducationofficial/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image
                    radius='none'
                    width={35}
                    alt='instagram'
                    src={icons.instagram}
                  />
                </a>
                <a
                  href='https://www.youtube.com/@sahasraeducation'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image
                    radius='none'
                    width={35}
                    alt='youtube'
                    src={icons.youtube}
                  />
                </a>
                <a
                  href='https://www.linkedin.com/company/sahasraeducationofficial'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image
                    radius='none'
                    width={35}
                    alt='youtube'
                    src={images.linkdin}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;