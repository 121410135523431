import React from 'react';
import "./styles.css";
import {
  Footer,
  CourseMini,
  ClassRoom,
  Articles,
  Introduction,
  Testimonials,
  Questions,
  Graphics
} from "../../components";

const Home = () => {
  return (
    <div>
      <Introduction />
      <Graphics/>
      <CourseMini />
      <Articles />
      <Testimonials />
      <Questions isShort={true} />
      <ClassRoom />
      <Footer />
    </div>
  )
}

export default Home;
