import React from 'react';
import { Contact, Footer, ClassRoom} from "../../components"

const Contact2 = () => {
  return (
    <div>
        <Contact/>
        <ClassRoom/>
        <Footer/>
    </div>
  )
}

export default Contact2