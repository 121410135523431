
const About = () => {
    return (
        <div className='bg-blue-100  flex justify-center md:p-20  text-black text-center p-10'>
            <div className='w-default-max-width flex flex-col gap-10'>
                <div className="flex flex-col gap-3">
                    <h1 className="text-3xl font-bold">Our Story</h1>
                    <p className="text-lg">
                        Sahasra is still in its early stages. It is being created and nurtured to realize the vision to make language learning a soulful experience. We are a
                        small team under the leadership of Hirak Gangopadhyay, committed to delivering the beauty of learning. Currently, we offer Japanese language
                        instruction, but we have strong plans to expand our offerings to include a wide range of languages in the future.
                    </p>
                </div>
                <div className="flex flex-col gap-3">
                    <h1 className="text-3xl font-bold ">Our Vision</h1>
                    <p className="text-lg">
                        Make language learning a soulful exprience.
                    </p>
                </div>
                <div className="flex flex-col gap-3">
                    <h1 className="text-3xl font-bold">Our Mission</h1>
                    <div className="flex flex-col gap-2">
                        <h5 className="text-xl font-semibold">Organize</h5>
                        <p className="text-lg">Structure language learning methodically to provide a comprehensive and holistic understanding of the language.</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h5 className="text-xl font-semibold">Evoke</h5>
                        <p className="text-lg">Cultivate a natural, intuitive grasp of language, transforming it from a mere skill into a deeply felt experience.</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h5 className="text-xl font-semibold">Direct</h5>
                        <p className="text-lg">Guide students on pathways that enable them to forge diverse and impactful professional journeys.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default About;