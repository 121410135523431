import React from 'react';
import { courses } from '../../constraints';
import { useMemo } from 'react';
import { Image, Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom"

const Course = () => {
    const navigate = useNavigate();

    const renderPaidCourses = useMemo(() => {
        return (
            <div className='max-w-[980px] mx-auto'>
                <div className='grid gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
                    {
                        courses.slice(0, 3).map((item, idx) => (
                            <div
                                key={idx}
                                className='bg-white cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg'
                                onClick={() => navigate("/CourseDetails/" + item.id)}
                            >
                                <Image
                                    radius='none'
                                    className='w-full h-auto'
                                    alt={item.title}
                                    src={item.banner}
                                />
                                <div className='p-8'>
                                    <h1 className='font-semibold text-lg mb-2'>{item.title}</h1>
                                    <p className='text-gray-800 italic text-small'>{item.description}</p>
                                    <div className='text-small mt-2'>
                                        <div className='bg-gray-100 p-2 border'>
                                            <div className='flex mb-2'>
                                                <h5 className='w-20 font-semibold'>Level</h5>
                                                <p>{item.level}</p>
                                            </div>
                                            <div className='flex'>
                                                <h5 className='w-20 font-semibold'>Duration</h5>
                                                <p>{item.duration} Months</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }, []);


    return (
        <div className='bg-gradient-custom flex justify-center  md:p-20 p-10' >
            <div>
                <div className='flex flex-col justify-center '>
                    <h1 className='text-white font-semibold text-3xl pb-16 text-center'>Our courses for your consideration</h1>
                    {renderPaidCourses}
                </div>
                <div className='mt-20 '>
                    <Button
                        size='lg'
                        className='bg-white'
                        radius='none'
                        endContent={
                            <FontAwesomeIcon icon={faArrowRight} />
                        }
                        onClick={() => navigate("/Courses")}
                    >
                        See all courses
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Course;