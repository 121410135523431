import React from 'react';
import testimonials from '../../constraints';
import { useMemo } from 'react';
import { Image } from '@nextui-org/react';

const Testimonials = () => {
    const renderPaidCourses = useMemo(() => {
        return (
            <div className='max-w-[980px] mx-auto'>
                <div className='grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
                    {
                        testimonials.slice(0, 3).map((item, idx) => (
                            <div key={idx} className='bg-white '>
                                <div className=' flex justify-center items-center' >
                                    <Image
                                        radius='full'
                                        className='md:w-[200px] w-[150px]'
                                        alt={item.title}
                                        src={item.image}

                                    />
                                </div>
                                <div className='pb-8 pt-8'>
                                    <p className='text-lg mb-2'>{item.article}</p>
                                    <p className='text-gray-800 font-semibold italic mt-10'>{item.name}</p>
                                    <p className='text-gray-700 '>{item.course}</p>
                                    <p className='text-gray-700 '>{item.preperation}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }, []);

    return (
        <div className='bg-white flex justify-center  md:p-20 p-10' >
            <div>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-black font-semibold text-3xl pb-16 text-center max-w-[700px]'>Meet our students who have already found their path towards success</h1>
                    {renderPaidCourses}
                </div>
            </div>
        </div>
    )
}

export default Testimonials;