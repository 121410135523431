import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();


    return (
        <div className='bg-white  gap-14 md:p-20 p-10'>
            <div className='max-w-[960px] m-auto'>
                <div className='flex justify-between gap-12'>
                    <div>
                        <h1 className='font-semibold text-xl mb-3'>Sahasra Education</h1>
                        <p onClick={() => navigate("/About")} className='text-blue-600 cursor-pointer mb-1'>About Us</p>
                        <p onClick={() => navigate("/Contact")}  className='text-blue-600 cursor-pointer mb-1'>Contact Us</p>
                        <p onClick={() => navigate("/Courses")} className='text-blue-600 cursor-pointer mb-1'>What We Offer</p>
                        {/* <p className='text-blue-600 cursor-pointer mb-1'>Careers</p> */}
                    </div>
                    {/* <div>
                        <h1 className='font-semibold text-xl mb-3'>More Info</h1>
                        <p className='text-blue-600 cursor-pointer mb-1'>Terms</p>
                        <p className='text-blue-600 cursor-pointer mb-1'>Privacy</p>
                        <p className='text-blue-600 cursor-pointer mb-1'>Help</p>
                    </div> */}
                </div>
                <div className='mt-20 '>
                    <p>© 2024 Sahasra Education. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer