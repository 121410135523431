import { Questions, ClassRoom, Footer } from "../../components"

const FAQ = () => {
    return (
        <div>
            <Questions />
            <ClassRoom/>
            <Footer/>
        </div>
    )
}

export default FAQ