import React from 'react';
import {
    Footer,
    ClassRoom,
    Course
} from "../../components";


const Home = () => {
    return (
        <div>
            <Course />
            <ClassRoom />
            <Footer />
        </div>
    )
}

export default Home;
