import React from 'react';
import { graphics } from '../../constraints';

const Graphics = () => {


    return (
        <div className='bg-white flex justify-center  md:p-20 p-10' >
            <div>
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-black font-semibold text-3xl pb-5 text-center max-w-[700px]'>Learn Japanese with us and create your career.</h1>
                    <p className='text-lg'>Learn the language in an organic way that you don’t just understand the language but you live with it.</p>
                    <div className='grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-14'>
                        {
                            graphics.map((item, idx) => (
                                <div
                                    key={idx}
                                    className='h-[200px] w-[200px] bg-cover bg-center overflow-hidden relative flex justify-center items-center text-center text-small p-10'
                                    style={{ backgroundImage: `url(${item.image})` }}
                                >
                                    {item.content}
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Graphics;