import React from 'react';
import { courses } from '../../constraints';
import { useMemo } from 'react';
import { Image } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

const Course = () => {
    const navigate = useNavigate();

    const renderPaidCourses = useMemo(() => {

        return (
            <div className='flex gap-10 max-w-[980px] flex-wrap sm:justify-start justify-center'>
                {
                    courses.filter(item => item.isPaid).map((item, idx) => (
                        <div
                            key={idx}
                            onClick={() => navigate("/CourseDetails/" + item.id)}
                            className='bg-white w-[300px] cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg'
                        >
                            <Image
                                radius='none'
                                className='w-[300px]'
                                alt={item.title}
                                src={item.banner}
                            />
                            <div className='p-8'>
                                <h1 className='font-semibold text-lg mb-2'>{item.title}</h1>
                                <p className='text-gray-800  text-small'>{item.description}</p>
                                <div className='text-small mt-2'>
                                    <div className='bg-gray-100 p-2 border'>
                                        <div className='flex mb-2'>
                                            <h5 className='w-20 font-semibold'>Level</h5>
                                            <p>{item.level}</p>
                                        </div>
                                        <div className='flex'>
                                            <h5 className='w-20 font-semibold'>Duration</h5>
                                            <p>{item.duration} Months</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }, [navigate])


    const renderFreeCourses = useMemo(() => {

        return (
            <div className='flex gap-10 max-w-[980px] flex-wrap sm:justify-start justify-center'>
                {
                    courses.filter(item => !item.isPaid).map((item, idx) => (
                        <div
                            onClick={() => navigate("/CourseDetails/" + item.id)}
                            key={idx} className='bg-white w-[300px] cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg'>
                            <Image
                                radius='none'
                                className='w-[300px]'
                                alt={item.title}
                                src={item.banner}
                            />

                            <div className='p-8'>
                                <h1 className='font-semibold text-lg mb-2'>{item.title}</h1>
                                <p className='text-gray-800  text-small'>{item.description}</p>
                                <div className='text-small mt-2'>
                                    <div className='bg-gray-100 p-2 border'>
                                        <div className='flex mb-2'>
                                            <h5 className='w-20 font-semibold'>Level</h5>
                                            <p>{item.level}</p>
                                        </div>
                                        <div className='flex'>
                                            <h5 className='w-20 font-semibold'>Duration</h5>
                                            <p>{item.duration} Months</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }, [navigate])


    return (
        <div className='bg-blue-100 flex justify-center pl-20 pt-10 pr-20 pb-10' >
            <div>
                <div className='flex flex-col justify-center '>
                    <h1 className='text-black font-semibold text-3xl pb-10 '>Paid Courses</h1>
                    {renderPaidCourses}
                </div>

                <div className='mt-16 flex flex-col justify-center'>
                    <h1 className='text-black font-semibold text-3xl pb-10 '>Free Courses</h1>
                    {renderFreeCourses}
                </div>
                {/* <div className='mt-20 '>
                    <p className='text-white text-2xl mb-5'>Watch Our Free Sessions</p>
                    <Button
                        className='bg-blue-200'
                        radius='none'
                        endContent={
                            <FontAwesomeIcon icon={faArrowRight} />
                        }
                    >
                        Checkout
                    </Button>
                </div> */}
            </div>
        </div>
    )
}

export default Course;