import React from 'react';
import { useMemo } from 'react';
import { Image, Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { articles } from '../../constraints';
import { useNavigate } from 'react-router-dom';

const Articles = () => {
    const navigate = useNavigate();

    const renderArticles = useMemo(() => {
        return (
            <div className='max-w-[980px] mx-auto'>
                <div className='grid gap-10 grid-cols-1 sm:grid-cols-2'>
                    {
                        articles.slice(0, 3).map((item, idx) => (
                            <div key={idx} className='bg-white w-full  '>
                                <Image
                                    radius='none'
                                    className='w-full'
                                    alt={item.title}
                                    src={item.banner}
                                />
                                <div className='p-8'>
                                    <h1 className='font-bold text-lg'>{item.title}</h1>
                                    <h2 className='font-semibold text-small mb-4 text-gray-700'>{item.semiTitle}</h2>
                                    <h2 className='font-semibold text-small text-gray-600 mb-2'><span className='italic'>Written By </span>{item.writer}</h2>
                                    <p className='text-gray-800'>{item.description}.</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }, []);


    return (
        <div className='bg-primary flex justify-center md:p-20 p-10' >
            <div>
                <div className='flex flex-col justify-center '>
                    <h1 className='text-white font-semibold text-3xl pb-16 text-center'>Read recent articles on Sahasra</h1>
                    {renderArticles}
                </div>
                <div className='mt-20 '>
                    <Button
                    onClick={() => navigate("/Articles")}
                        size='lg'
                        className='bg-white'
                        radius='none'
                        endContent={
                            <FontAwesomeIcon icon={faArrowRight} />
                        }
                    >
                        See all articles
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Articles;