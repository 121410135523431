import { images } from '../../assets';
import { Image } from '@nextui-org/react';
import { ReactTyped } from 'react-typed';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./styles.css"

const Introduction = () => {
    return (
        <div
            className='h-calc-full-minus-60 bg-cover bg-center overflow-hidden relative '
            style={{ backgroundImage: `url(${images.background})` }}
        >
            <div className='h-calc-full-minus-60 bg-cover bg-center overflow-hidden relative w-default-max-width m-auto'>
                <div className='hidden md:block w-[350px] absolute md:-bottom-20  md:right-0'>
                    <Image
                        src={images.character}
                        alt='character'
                        className='w-[400px]'
                    />
                </div>

                <div className='absolute w-full h-full md:left-0 left-5 top-1/3  z-10 p-4 sm:p-6  hidden md:block'>
                    <h1 className='text-primary font-bold text-4xl sm:text-5xl header'>
                        <ReactTyped
                            strings={[
                                'SAHASRA\nEDUCATION',
                            ]}
                            typeSpeed={50}
                            backSpeed={25}
                            backDelay={1000}
                            loop
                            className='text-center'
                        />
                    </h1>
                    <p className='mt-2 text-xl sm:text-2xl text-gray-900 text-wrap flex'>
                        Premium Language Courses<br />
                        Online Live Classes<br />
                        Expert Teachers and Mentors<br />
                        Free Mindful Sessions<br />
                        Career Guidance<br />
                    </p>

                </div>

                <div className='h-full  z-10 p-10   flex md:hidden  flex-col justify-center items-center w-dvw backdrop-blur-sm' >
                    <h1 className='text-primary font-semibold text-[4rem] header h-[15rem]'>
                        <ReactTyped
                            strings={[
                                'SAHASRA\nEDUCATION',
                            ]}
                            typeSpeed={50}
                            backSpeed={25}
                            backDelay={1000}
                            loop
                            className='text-center'
                        />
                    </h1>
                    <p className='mt-2 text-2xl text-gray-900 text-wrap flex '>
                        Premium Language Courses<br />
                        Online Live Classes<br />
                        Expert Teachers and Mentors<br />
                        Free Mindful Sessions<br />
                        Career Guidance<br />
                    </p>

                    <div className='w-16 h-16 bg-white rounded-full flex justify-center items-center mt-16 scroll-indicator'>
                        <FontAwesomeIcon
                            size='2x'
                            icon={faArrowDown}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction