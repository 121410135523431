import React from 'react';
import { Header } from "..";
import { icons } from "../../assets";
import { Image } from '@nextui-org/react';

const Layout = ({ children }) => {
    return (
        <div className=''>
            <Header />
            <div>
                {children}
                <a href="https://wa.me/+919332355074" className='fixed bottom-10 left-10 cursor-pointer rounded-full shake'>
                    <Image
                        className='w-[50px] h-[50px]'
                        src={icons.whatsApp}
                        alt='whats-app'
                    />
                </a>
            </div>

        </div>
    );
}

export default Layout