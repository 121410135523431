import React from "react";
import {
  Image,
  Navbar,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  NavbarContent,
  NavbarItem,
  // Link
} from "@nextui-org/react";
import { icons } from "../../assets";
import { menuItems } from "../../constraints";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Navbar
      className="bg-primary text-white border-b-1 border-black"
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      shouldHideOnScroll
    >
      <NavbarContent className="sm:hidden pr-3" justify="center">
        <NavbarBrand>
          <Image
           onClick={() =>{ navigate("/")
            setIsMenuOpen(false)
           }} 
           className="cursor-pointer w-32"
            radius="none"
            src={icons.logo}
            alt="logo"
          />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-8" justify="end">
        <NavbarBrand >
          <Image
           onClick={() => {
            setIsMenuOpen(false)
            navigate("/")}}
            radius="none"
            src={icons.logo}
            alt="logo"
           className="cursor-pointer w-32"
          />
        </NavbarBrand>
        {
          menuItems.map((item, idx) => (
            <NavbarItem key={idx}>
              <Link className="text-white" to={item}>
                {item}
              </Link>
            </NavbarItem>
          ))
        }
      </NavbarContent>

      <NavbarMenu  >
        {menuItems.map((item, index) => (
          <NavbarMenuItem 
          onClick={() => setIsMenuOpen(false)}
          key={`${item}-${index}`} >
            <Link
              to={item}
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
