import React, { useState } from 'react';
import { Image, } from '@nextui-org/react';
import { images } from '../../assets';
import { ClassRoom, Footer } from "../../components"

const Articles = () => {
    const [state1, setState1] = useState(true);
    const [state2, setState2] = useState(true);

    return (
        <>
            <div className='bg-blue-100 flex justify-center  md:p-10 ' >
                <div>
                    <div className='flex flex-col justify-center '>
                        <div className='flex md:gap-10 gap-1 max-w-[980px] flex-wrap sm:justify-start justify-center'>
                            <div className='bg-white'>
                                <div className='flex justify-center items-center'>
                                    <Image
                                        className='w-fit md:mt-1'
                                        alt='art-1'
                                        src={images.article1}
                                        radius='none'
                                    />
                                </div>
                                <div className='p-10 flex flex-col gap-5'>
                                    <h1 className='font-bold text-2xl'>
                                        Shamanism Practices in Ancient Japan
                                    </h1>
                                    <h2 className='font-semibold text-xl'><span className=' italic'> Written By </span>Kundan Das</h2>
                                    {
                                        state1 ? (
                                            <p>
                                                Shamanism is an ancient practice which is present in many cultures and societies throughout human history. In Japan,
                                                shamanism has a long and rich tradition, dating back to the prehistoric Jomon period. This paper will explore the
                                                shamanistic practices of old Japan, focusing on the beliefs, rituals, and role of the shaman in Japanese society. It is a
                                                belief system based on the idea that there exists a spiritual world parallel to the physical realm, has been a prominent
                                                feature of many ancient cultures around the world, including Japan. In ancient Japan, shamanistic practices played a
                                                significant role in religious and cultural traditions, influencing the lives of the people and shaping their spiritual beliefs.
                                                Shamanistic practices were deeply integrated into ancient Japanese society, particularly in relation to agricultural
                                                activities, fertility rites, and community celebrations.
                                            </p>
                                        ) : (
                                            <>
                                                <p>
                                                    Shamanism is an ancient practice which is present in many cultures and societies throughout human history. In Japan, shamanism has a long and rich tradition, dating back to the prehistoric Jomon period. This paper will explore the shamanistic practices of old Japan, focusing on the beliefs, rituals, and role of the shaman in Japanese society. It is a belief system based on the idea that there exists a spiritual world parallel to the physical realm, has been a prominent feature of many ancient cultures around the world, including Japan. In ancient Japan, shamanistic practices played a significant role in religious and cultural traditions, influencing the lives of the people and shaping their spiritual beliefs. Shamanistic practices were deeply integrated into ancient Japanese society, particularly in relation to agricultural activities, fertility rites, and community celebrations.
                                                    Shamanism in Japan is based on the belief that spirits or kami are present in all things, including natural phenomena such as rocks, trees, and mountains, as well as human beings and animals. These spirits are believed to have the power to influence human affairs, both positively and negatively. Therefore, the shaman's role is to mediate between the human world and the spirit world, communicating with the kami on behalf of individuals and the community.
                                                    The shaman's primary tool for communicating with the spirits was ritual. Shamanistic rituals in old Japan were often performed outdoors, in natural settings such as forests or mountains. These rituals included chanting, dancing, and offerings of food and drink to the kami. The shaman would often enter a trance-like state during the ritual, allowing them to communicate with the spirits more easily.
                                                    One of the most important shamanistic rituals in old Japan was the harvest festival. This festival was held in honour of the agricultural deities, who were believed to be responsible for the success or failure of the harvest. The shaman would lead the community in a series of rituals, including offering food and drink to the deities, and performing a dance to ensure a bountiful harvest.
                                                    In old Japan, the shaman played an essential role in society, acting as a mediator between the human world and the spirit world. They were often consulted on matters of health, fertility, and other important issues, as they were believed to have a direct line of communication with the kami.
                                                    In addition to their role as spiritual leaders, shamans were also valued for their healing abilities. They were believed to have the power to cure illnesses and injuries by communicating with the spirits and channeling their energy into the patient.
                                                </p>
                                                <div className='flex flex-col gap-3'>
                                                    <h3 className='italic'>Few types of Shamans in ancient Japan</h3>
                                                    <h2 className='font-semibold'>1. Miko</h2>
                                                    <p>
                                                        Miko, also known as shrine maiden, is a young priestess who works at a Shinto shrine. Miko were once likely seen as shamans, but are understood in modern Japanese culture to be an institutionalized role in daily life, trained to perform tasks, ranging from sacred cleansing to performing the sacred Kagura dance. Miko are often younger relatives of Shinto priests, such as daughters or granddaughters.
                                                        The Miko played an important role in the religious practices of ancient Japan, which were centered around the worship of various deities known as kami. Miko were believed to have a special connection to the divine and were often considered to be intermediaries between humans and the kami. Miko were typically young women, often chosen from noble or influential families. They would undergo training and rituals to prepare themselves for their role. Miko were responsible for performing sacred dances, prayers, and purification rituals at the shrines. They would also assist in divination and fortune-telling, providing guidance and advice to worshippers. Over time, the role of Miko has evolved, and today they continue to serve in Shinto shrines. While the ancient practices and traditions have been modified and adapted, Miko still play a significant role in the preservation of Shinto customs and rituals in modern-day Japan.
                                                    </p>
                                                </div>
                                                <div className='flex flex-col gap-3'>
                                                    <h2 className='font-semibold'>2. Dogū</h2>
                                                    <p>
                                                        Dogū appeared during the Jomon period. The clay figurine was in the shape of a woman. People valued the fact that women have the power of life. Due to that, the clay figurines were female. People believed that it had magical powers and prayed to dog dogū. The term "Dogu" refers to a type of ancient Japanese clay figurine that dates back to the Jomon period, which lasted from approximately 14,000 BCE to 300 BCE. These figurines are known for their distinctive forms, often depicting humanoid or animal-like figures with exaggerated features.
                                                        The exact purpose of Dogu remains a subject of debate among archaeologists and historians. Some theories suggest that they might have represented deities, ancestors, or even shamanic spirits. Others propose that Dogu served ritualistic or ceremonial purposes, possibly relating to fertility, agriculture, or shamanic practices. Also, some believe that the figurines might have been used in daily life as toys, charms, ог talismans. The discovery of numerous Dogu figurines at archaeological sites throughout Japan indicates their widespread presence and importance in Jomon society. Today, these ancient artifacts provide valuable insights into the cultural and artistic practices of Japan's prehistoric past. Many Dogu figurines are displayed in museums, both in Japan and around the world, allowing people to appreciate and study these fascinating relics from ancient Japan.
                                                    </p>
                                                </div>
                                                <div className='flex flex-col gap-3'>
                                                    <h2 className='font-semibold'>3. Ainu Shamanism</h2>
                                                    <p>
                                                        The Ainu are Japan's largest indigenous people. They are the descendants of the first people of the Japanese archipelago, commonly called the Jomon people, who immigrated to the Japanese archipelago over 10,000 years ago. Shamans in ancient Ainu culture were both male and female, and their work consisted of numerous ceremonies and festivals throughout the year.
                                                        Shamanism in ancient Japan was an essential aspect of Japanese culture and society. It provided a means of communicating with the spirits and ensured the well-being of individuals and the community as a whole. Although shamanism has declined in modern Japan, its legacy can still be seen in various aspects of Japanese culture, including the reverence for nature and the continued practice of certain traditional rituals.
                                                    </p>
                                                </div>

                                                <div className='flex flex-col gap-3'>
                                                    <h3 className='font-semibold'>Reference</h3>
                                                    <div>
                                                        <p className='italic'>Japanese Shamanism: trance and possession (Book)</p>
                                                        <p>
                                                            <a
                                                                className='text-blue-600 underline break-all'
                                                                href='https://factsanddetails.com/japan/cat16/sub183/item595.html'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                https://factsanddetails.com/japan/cat16/sub183/item595.html
                                                            </a>
                                                        </p>
                                                        <p>
                                                            <a
                                                                className='text-blue-600 underline break-all'
                                                                href='https://en.m.wikipedia.org/wiki/Miko'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                https://en.m.wikipedia.org/wiki/Miko
                                                            </a>
                                                        </p>
                                                        <p>
                                                            <a
                                                                className='text-blue-600 underline break-all'
                                                                href='https://en.m.wikipedia.org/wiki/Dog%C5%AB'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                https://en.m.wikipedia.org/wiki/Dog%C5%AB
                                                            </a>
                                                        </p>
                                                        <p>
                                                            <a
                                                                className='text-blue-600 underline break-all'
                                                                href='https://www.culturalsurvival.org/publications/cultural-survival-quarterly/ainu-shamanisma-forbidden-path-universal-knowledge'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                https://www.culturalsurvival.org/publications/cultural-survival-quarterly/ainu-shamanisma-forbidden-path-universal-knowledge
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>


                                            </>
                                        )
                                    }

                                    <h3
                                        className=' text-blue-600 underline cursor-pointer'
                                        onClick={() => setState1(pre => !pre)}
                                    >
                                        {state1 ? "Show Full" : "Show Less"}

                                    </h3>
                                </div>
                            </div>
                            <div className='bg-white'>
                                <Image
                                    src={images.article2}
                                    radius='none'
                                    alt='alt-2'
                                />
                                <div className='p-10 flex flex-col gap-5'>
                                    <h1 className='font-bold text-2xl'>
                                        Ainu, the Strange Race
                                    </h1>
                                    <h2 className='font-semibold '>An Intriguing Story of Japan’s Forgotten Aborigine </h2>
                                    <h2 className='font-semibold text-xl'><span className=' italic'> Written By </span>Hirak Gangopadhyay</h2>
                                    {
                                        state2 ? (
                                            <p>
                                                To introduce the concept of Ainu, it’s better to begin with a journal. “They have no history, their traditions are scarcely
                                                worth the name, they claim descent from a dog, their houses and persons swarm with vermin, they are sunk in the
                                                grossest ignorance, they have no letters or any numbers above a thousand, they are clothed in the bark of trees and the
                                                untanned skins of beasts, they worship the bear, the sun, moon, fire, water, and I know not what, they are uncivilizable
                                                and altogether irreclaimable savages, yet they are attractive, and in some ways fascinating, and I hope I shall never
                                                forget the music of their low sweet voices, the soft light of their mild brown eyes and the wonderful sweetness of their
                                                smile.”1 This is how an adventurous traveller to Japan in the late 1800s, Isabella Bird, recalled the northern aborigine of
                                                Japan, named Ainu. Ainu is the indigenous people of Hokkaido, Sakhalin, and the Kuril Islands who are culturally and
                                                physically distinct from their Japanese neighbours. The word ‘Ainu
                                                ’ means ‘human’ in the Hokkaidō dialect of the Ainu
                                                language...
                                            </p>
                                        ) : (
                                            <>
                                                <p>

                                                    To introduce the concept of Ainu, it’s better to begin with a journal. “They have no history, their traditions are scarcely worth the name, they claim descent from a dog, their houses and persons swarm with vermin, they are sunk in the grossest ignorance, they have no letters or any numbers above a thousand, they are clothed in the bark of trees and the untanned skins of beasts, they worship the bear, the sun, moon, fire, water, and I know not what, they are uncivilisable and altogether irreclaimable savages, yet they are attractive, and in some ways fascinating, and I hope I shall never forget the music of their low sweet voices, the soft light of their mild brown eyes and the wonderful sweetness of their smile.”1 This is how an adventurous traveller to Japan in the late 1800s, Isabella Bird, recalled the northern aborigine of Japan, named Ainu.

                                                </p>
                                                <p>
                                                    Ainu is the indigenous people of Hokkaido, Sakhalin, and the Kuril Islands who are culturally and physically distinct from their Japanese neighbours. The word ‘Ainu’ means ‘human’ in the Hokkaidō dialect of the Ainu language.
                                                </p>
                                                <p>
                                                    The racial origin of the Ainu is extremely vague and complex. Theories put forth often concluded in different decisions and never were agreed upon by the whole. Most European scholars and anthropologists suggested their connection to that of the Caucasoid. But recent studies observing more minutes of the gene structure, dental orthography, and fingerprinting ensure the ancestry of Mongoloid.
                                                </p>
                                                <p>
                                                    On the other hand, the Japanese scholars are likely to prove the connection to that of the Jomon people, the earliest known habitants of Japan who settled down the archipelago around 20000 BCE and developed a hunting and gathering-based lifestyle. It was the arrival of Yayoi who immigrated almost the entirety of the land and introduced agriculture. The modern Japanese seems to be the decedents of this new Yayoi. According to Lee and Hasegawa of Waseda university, though the Jomon was scattered around the archipelago; particularly the Jomon concentrated in Hokkaido, the northernmost island of Japan was influenced a great by the people of Okhotsk, now the eastern Russian territory. Historian and linguist Joran Smale even suggested the origin of the Ainu language from the ancient Okhotsk people. Nevertheless, pure Ainu blood could have come down from the combination of the ancient habitants of Okhotsk and the Jomon of Hokkaido.
                                                </p>
                                                <p>
                                                    Such a connection to that of Okhotsk can be supported by the history of the bear cult or bear worship. Bear cult is the religious practice of North Eurasian Ethnic Groups such as Sami, Nivkh, Slavs, and Finns. But the study has never proved bear cult as a practice of the Jomon of Japan from the historical traces found. But in Ainu, it has been a significant part of the indigenous culture for a span of time. Hence the influential nature of ancient Okhotsk culture could have been linked to that of Ainu.
                                                </p>
                                                <p>
                                                    Because of the lack of historical evidence to draw a clear line of the Ainu timeline, there are many missing links. And the valid sources of studying are the journals of exploring travellers, archaeological remains, and rarely some officially accounted royal court chronicles. As there was abundant time for Ainu culture and population especially concentrated in the land of Hokkaido, some migrated to Sakhalin and Kurile islands, and some to northern Honshu and Kamchatka peninsula as early as the first millennium AD. in Kamchatka and Honshu, the descendants of Ainu, nowadays, better known as Matagi hunters, practice a large number of Ainu vocabularies in dialect, or the Ainu toponyms which are found in northern Honshu mainly along the western coast and the Tohoku region, can support as important evidence of such expeditions. Ainu loanwords in the languages of ‘Ulta’ and ‘Ulch’ people are none of the else.
                                                </p>
                                                <p>
                                                    Post-settlement, the culture of these migrated Ainu groups was influenced more or less by local cultures respectively. An Intensive association with the indigenous population and later the Mongol invasion and colonization between 1263 to 1320 in Sakhalin, made Ainu the subject of tributary along with the Gilyaks. In 1308, the Sakhalin Ainu fought a battle against the Mongolian Yuan dynasty and the obvious defeat extremized their pacification based on a tribute system. Through this tribute system, Sakhalin Ainu again got reconnected with the Hokkaido Ainu in trading engagements. This way the Japanese ironware reached Manchus while the Chinese brocade and cotton to Osaka.

                                                </p>
                                                <p>
                                                    After the fall of the Mongolian-led Yuan dynasty, Sakhalin came under Ming rule in 1368 and forth. During this time the assimilation of Ainu with the indigenous population became a subject as the Ainu women living in Sakhalin married Han Chinese Ming officials and Chinese cultures such as the Chinese New Year, Chinese God, Chinese motifs like dragon, spirals, scrolls, and even the tools of agriculture, husbandry came to a significant existence.

                                                </p>
                                                <p>
                                                    On the other hand, the fate of the Hokkaido Ainu flowed down through different episodes and dilemmas. The intriguing location of Ainu with the encompassing presence of three major cultures; Japan, China, and Russia; the course of continuation was frequently turmoiled by the social and political chaos of each. Around the 13th century, Japanese association with Ainu in Hokkaido and northern Honshu began on the socio-trading structure. This association led to a diversified result as both started understanding each other and occurring incidents of violence at the same time. In 1456, a revolution outburst in southern Hokkaido originated from an argument between a Japanese blacksmith and an Ainu. It went on with such aggression that the Ainu announced war against the Japanese, and burnt down several houses creating great distress under the leadership of Koshamain but eventually subjugated to the Japanese leader Takeda Nobuhiro. This incident was the imminent emergence of the clan of Matsumae by the descendants of Nobuhiro.

                                                </p>
                                                <p>
                                                    Later the relationship between the two was never of favourable conditions. During the Edo period (1601-1868) as the Matsumae clan was officially accounted for trading with the Ainu by the Shogunate Bakufu, Ainu became the subject of exploitation in the Japanese fishing industry and eventually, the increasing tension led to further disturbances. Two most important Ainu revolts against the Japanese, the Shakushain’s Revolt in 1669 and the Menashi-Kunashir Battle in 1789 ventured the continuous struggle of these backward aboriginals. In 1779, the control over Ainu was shifted directly to that of the Shogunate Tokugawa intensifying the Ainu situation with more strict laws by sending Ainu men to merchant subcontractors for five to ten years and forcibly making the Ainu women marry Japanese people. Again in 1821 control shifted to Matsumae and again to the Shogunate in 1854.

                                                </p>
                                                <p>
                                                    But still the most significant of the history was yet to come, the Meiji Restoration. With a more extensive vision to confront the Russian efforts of southward expansion via Hokkaido and to develop occupation for Samurai class people, the ruler decided the unification of Japan. In 1899, the Ainu was officially labelled as “Former Aboriginals” whose indigenous way of life was forbidden. The usage of the Ainu language, practicing bear cult, or the mouth tattooing of grown-up females were strictly forbidden. This time, Hokkaido became heavily populated by the Japanese as the ownership of the lands was allotted to them rather than the Ainu. The Ainu were encouraged to assimilate with the Japanese way of life, accepting Japanese as the language to speak, performing marriages with them, and taking up an agricultural livelihood.

                                                </p>
                                                <p>
                                                    Meiji Restoration, which resulted to last around one hundred years came as a crucial period of Ainu history. Because of the Japanese efforts to make them assimilate into the singular culture, destroyed their individual qualities. The population has decreased so much that the race is on the brink of extinction. No pure-blood Ainu can be traced down because of the mixed marriages.

                                                </p>
                                                <p>
                                                    For nearly 100 years, as the Ainu people lived under a policy of extinction by assimilation, it made a huge loss to this ethnic group. But since 1997, government funding and cultural freedom have allowed the Ainu people to start regaining and preserving their precious way of life through Ainu language courses, radio stations, and public performances. But undoubtedly, these government activities to patronage the resurrection of this race can bring very tiny hope if the admiration of common people doesn’t begin to admit local identities.

                                                </p>
                                                <p>
                                                    The Ainu customs and habits are significantly distinct from any other race on this earth. A typical Ainu settlement or village community known as ‘Kotan,’ is consisted of five to twenty family houses located along the water sources. The Sakhalin Ainu had some seasonal alternation in the settlement pattern. In winter, they live on the further island in semisubterranean pit houses, and in summer live by the shore. But the Ainu in Hokkaido is likely not to be in such seasonal shifts.

                                                </p>
                                                <p>
                                                    These dwellings are rectangular and poll-framed with a single spacious room linked to a small room-like entryway. The walls are made of miscanthus or bamboo grass with a cover of the thatched roof which holds a covered opening for the smoke to escape. The earthen floor is covered with mats or woven grass or animal pelts for sitting and sleeping.

                                                </p>
                                                <p>
                                                    The outer appearance of Ainu which seems to be obscure and interesting at the same time has very peculiar specialties to pay attention to. A traditional Ainu attire is made from the inner bark of the elm tree called ‘Attus’ or ‘Attusi,’ which is a simple shape of a long robe with short sleeves. These robes are always designed with unique geometric patterns. For men, it spreads to a calf-length while for women it spreads to the ankle and is worn with a woven belt, and in winter with a sleeveless jacket. Both the Ainu men and women keep shoulder-length hair when the men never shave after a certain age and women start tattooing a circle around the mouth at puberty which completes before marriage as a sign of adulthood and preparedness for marriage.

                                                </p>
                                                <p>
                                                    As the race has been a hunter, gatherer, and fisherman community until the occurrence of the Meiji Restoration, the ethnic food habit is connected to so. They mainly came to eat mammals on the land such as bears, sheep, deer, and so on. River fishes are also on their daily dietary list along with various vegetables and fruits. But unlike the Japanese, they never eat raw and while eating men uses chopsticks and women some wooded spoon.

                                                </p>
                                                <p>
                                                    The religion that has come being practiced by the aboriginals for a long span of time, confers the idea of heavenly spirits that dwell in everything like water, tree, mountain, fire, and so on called ‘Kami.’ This animist faith is quite similar to that of the Japanese. Though there are heavenly spirits dwelling in everything, the ‘Kami’ of the mountain and the kami of the bear are most important. The famous bear cult which is entirely banned these days was once their greatest of ceremonies. The bear cult not only acclaimed the unity of the clan but also was mediate to show the political and social power of the clan leader. The bear sacrificing or ‘Lotame’ required a bear cub to be raised in the household as the own child of the family. And after a certain age of around two or three years, the bear was sacrificed. They believed that the ‘Kami’ living inside the bear could be free and be back to their place if the rituals were performed well.

                                                </p>
                                                <p>
                                                    Besides the concept of ‘Kami,’ the faith in the existence of the soul in every human being regards spiritual necessity. It is believed that a soul leaves the body while sleeping and roams around places physically we never were. Even a soul can visit the land of the dead. Based on a performance of shamanism which is not an exclusively male role, the shaman’s soul can travel to the land of the dead and snatch back the soul of a nearly dead person, reviving someone who is nearing death.

                                                </p>
                                                <p>
                                                    The Ainu language which is still a great mystery because of its unknown origin and flows surprisingly has never been in any written form. In recent days either it is written based on the phonetics in the Roman alphabet or in the Katakana script of Japanese. Ainu language has lived by being passed down from parent to child for countless years. And this beautiful process of continuation designed the advent of traditional Ainu storytelling, ‘Yukar,’ a form of oral literature. Yukar is usually sung as folklore that speaks about tales and legends with traditional musical instruments like Mukkuri. Other traditional literary musical genres are Upopo, Yaisama, and Rekuhkara and the other important traditional instruments are Tonkori and Kakko.

                                                </p>
                                                <p>
                                                    The trend of this oral literary tradition is so abundant and articulate that it can be compared only to the Greek tradition. Mainly diversified in forms of prose and lyrics, this is all source of knowledge for Ainu people. It even contains an entirely unique creation mythology.

                                                </p>
                                                <p>
                                                    With the restrictions placed on the use of the Ainu language in 1899, Ainu speakers have all but disappeared. Today the language has less than 15 native speakers, all of which are above the age of 60, making Ainu a "critically endangered" language. Originally, the Ainu language had three main dialects: Hokkaido, Sakhalin, and Kurile. However, the Hokkaido dialect is the only one that survives today.

                                                </p>
                                                <p>
                                                    Although the Ainu culture is on the brink of extinction, it seems that more and more effort is going into bringing it back to life. More recent landmarks in Ainu history are included the official recognition of the Ainu as the indigenous people of Japan in 2008 and the formation of the Ainu political party in 2012. These days, young Ainu such as ‘The Ainu Rebels’ are doing their best to create a new identity for their people and a Japan more open to minorities. Since the expansion of the story of the Ainu can't be limited alone to Japan, but to every nation with different titles; we all are meant to encourage the culture of our surrounding and embrace our native identities.
                                                </p>

                                                <div className='flex flex-col gap-3'>
                                                    <h3 className='font-bold'>End Note</h3>
                                                    <p>
                                                        1.	Isabella Bird, Unbeaten Tracks in Japan, G. P. Putnam’s Sons, London, 1881. P.
                                                    </p>
                                                </div>

                                                <div className='flex flex-col gap-3'>
                                                    <h3 className='font-bold'>Reference</h3>
                                                    <p>
                                                        2.	A. M. Prokhrov (Edited), Great Soviet Encyclopaedia, Vol. 1, Sovetskaia Entsiklopediia Publishing House, Moscow, 1970<br />
                                                        3.	David Levinson & Karen Christen (Edited), Encyclopaedia of Modern Asia, Vol. 1, Charles Scribner’s Sons, USA, 2002<br />
                                                        4.	L. Collingwood (Edited), 21st Century Universal Encyclopaedia, Vol. 1, Regency Publishing, Sydney, 1996<br />
                                                        5.	Louis Frederic, Japan Encyclopaedia, The Belknap Press of Harvard University Press, London, 2002<br />
                                                        6.	Peter N. Stearns, The Oxford Encyclopaedia Of the Modern World, Vol.1, Oxford University Press, USA, 2008<br />
                                                        7.	William D. Halsey & Bernard Johnston (Edited), Collier’s Encyclopaedia with Bibliography and Index, Vol. 1, Macmillan Educational Company, New York, 1989<br />

                                                    </p>
                                                </div>
                                                <div className='flex flex-col gap-3'>
                                                    <h3 className='font-bold'>Website</h3>
                                                    <p>
                                                        1. Michael Ray, Ainu, Britannica,
                                                        <a
                                                            className='text-blue-600 underline break-all cursor-pointer'
                                                            href='https://www.britannica.com/topic/ainu'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            https://www.britannica.com/topic/ainu
                                                        </a>
                                                        <br />
                                                        2. Sarah W, The Ainu, Tofugu,
                                                        <a
                                                            className='text-blue-600 underline break-all cursor-pointer'
                                                            href='https://www.tofugu.com/japan/ainu-japan/'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            https://www.tofugu.com/japan/ainu-japan/
                                                        </a>
                                                    </p>
                                                </div>

                                            </>
                                        )
                                    }

                                    <h3

                                        className=' text-blue-600 underline cursor-pointer'
                                        onClick={() => setState2(pre => !pre)}
                                    >
                                        {state2 ? "Show Full" : "Show Less"}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ClassRoom />
            <Footer />
        </>
    )
}

export default Articles;