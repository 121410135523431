import { courses_details } from "../../constraints";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClassRoom, Footer } from "../../components";

const CourseDetails = () => {
    const [data, setData] = useState({});
    const { id } = useParams();

    useEffect(() => {
        const temp = courses_details.find(item => item.id === Number(id));
        setData(temp);
    }, [id])



    return (
        <div>
            <div className='bg-blue-100 flex justify-center md:p-20 p-10 text-black'>
                <div className='w-default-max-width flex flex-col gap-5'>
                    <p className="font-bold text-3xl text-center uppercase">{data?.course_title}</p>
                    <p className="font-semibold text-center text-2xl">{data?.description}</p>
                    <div className="flex justify-between mt-10 flex-wrap">
                        <div className="flex flex-col gap-3">
                            <h1 className="font-bold uppercase text-xl ">Course Highlights</h1>
                            <div>
                                <h2 className="font-semibold text-large">Preperation</h2>
                                {
                                    data?.course_highlights?.Preparation.map((item, idx) => (
                                        <p key={idx}>
                                            {item}
                                        </p>
                                    ))
                                }
                            </div>
                            {
                                data?.course_highlights?.Practice && (
                                    <div>
                                        <h2 className="font-semibold text-large">Practice</h2>
                                        {
                                            data?.course_highlights?.Practice.map((item, idx) => (
                                                <p key={idx}>
                                                    {item}
                                                </p>
                                            ))
                                        }
                                    </div>
                                )
                            }

                            {
                                data?.course_highlights?.Suggestions && (
                                    <div>
                                        <h2 className="font-semibold text-large">Suggestions</h2>
                                        {
                                            data?.course_highlights?.Suggestions.map((item, idx) => (
                                                <p key={idx}>
                                                    {item}
                                                </p>
                                            ))
                                        }
                                    </div>
                                )
                            }


                        </div>
                        <div className="flex flex-col gap-3 mt-10">
                            <div>
                                <h2 className="font-semibold text-large">Course Duration</h2>
                                <p>{data?.course_duration}</p>
                            </div>
                            <div>
                                <h2 className="font-semibold text-large">Course Value</h2>
                                <p>{data?.course_venue}</p>
                            </div>
                            <div>
                                <h2 className="font-semibold text-large ">Course Timing</h2>
                                <p>2 Classes/Week</p>
                                <p>1.5 Hours/Each Session</p>
                                <p>Evening Classes</p>
                            </div>
                            <div>
                                <h2 className="font-semibold text-large">Next Batch Commencement</h2>
                                <p>{data?.next_batch_commencement}</p>
                            </div>
                            <div>
                                <h2 className="font-semibold text-large">Course Fee</h2>
                                <p style={{ textDecoration: 'line-through' }}>{data?.old_price}</p>
                                <p>{data?.course_fee}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center mt-10">
                        <div className=" pt-5 pb-5 pl-10 pr-10 flex flex-col gap-2 font-semibold text-black text-center" >
                            <h1>REGISTER NOW</h1>
                            <p>Call or WhatsApp</p>
                            <p>+91 9332355074</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClassRoom />
            <Footer />
        </div>
    )
}

export default CourseDetails;