import logo from "./icons/logo.png";
import logo2 from "./icons/logo-2.png";
import facebook from "./icons/facebook.png";
import instagram from "./icons/instagram.png";
import youtube from "./icons/youtube.png";
import linkdin from "./icons/linkedin.png";
import whatsApp from "./icons/whatsapp.png";

import background from "./images/background.png";
import character from "./images/character.png";
import course1 from "./images/course1.png";

import article1 from "./images/article1.png";
import article2 from "./images/article2.png";

import t1 from "./images/T1.png";
import t2 from "./images/T2.png";
import t3 from "./images/T3.png";

import user1 from "./images/Tirthankar.JPG";
import user2 from "./images/Sumanta.jpg";
import user3 from "./images/Chitra.jpg";
import user4 from "./images/Mohona.jpg";

import slot1 from "./images/slot1.png";
import slot2 from "./images/slot2.png";
import slot3 from "./images/slot3.png";
import slot4 from "./images/slot4.png";

export const icons = {
    logo,
    logo2,
    facebook,
    instagram,
    youtube,
    whatsApp
}

export const images = {
    character,
    background,
    course1,
    t1,
    t2,
    t3,
    article1,
    article2,
    user1,
    user2,
    user3,
    user4,
    slot1,
    slot2,
    slot3,
    slot4,
    linkdin
}