import './App.css';
import { Layout } from './components';
import { Home } from "./pages";
import { BrowserRouter } from "react-router-dom";
import Routes from './routes';
import { icons } from "./assets";
import { Image } from '@nextui-org/react';

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Layout>
          <Routes />
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
