import React from 'react';
import {
  Footer,
  ClassRoom,
} from "../../components";
import { images } from '../../assets';
import { Image } from '@nextui-org/react';

const Home = () => {
  return (
    <div
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
        overflow: "scroll"
      }}
      className='bg-blue-100'
    >
      <div className='flex  justify-center md:mt-10  md:mb-10'>
        <div className='flex md:gap-5 gap-1 max-w-[980px] flex-wrap sm:justify-start justify-center'>
          <div className='md:w-[980px] w-full  bg-opacity-70 p-10 grid grid-cols-1  gap-5 md:ml-10 md:mr-10 '>
            <p className='text-black text-2xl flex justify-center items-center'>
              Read the stories of some of our students who has<br />
              already found their paths towards success...
            </p>
          </div>
          <div className='md:w-[980px] w-full  bg-opacity-70 p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:ml-10 md:mr-10'>
            <div className='flex justify-center items-center flex-col gap-6 flex-wrap' >
              <Image
                alt='user1'
                src={images.user2}
                radius='full'
                width={250}
              />
              <p className='text-xl text-black '>
                Sumanta Pal<br />
                Japanese Bilingual at Sutherland<br />
                N2 Aspirant<br />
              </p>
            </div>
            <div className='flex justify-center items-center'>
              <p className='text-black text-lg italic'>
                “My life has been changed being here.
                I have learned the language from very
                beginning and now working at a
                Japanese company. My suggestion
                will always be Sahasra.”
              </p>
            </div>
          </div>
          <div className='md:w-[980px] w-full  bg-opacity-70 p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:ml-10 md:mr-10'>
          <div className='flex justify-center items-center'>
              <p className='text-black text-lg italic'>
                "My journey at Sahasra Education has been
                nothing less than empowering. As a Japanese
                Interpreter at Suzuki, language precision is
                everything, and Sahasra provided me with all the
                tools necessary to succeed. The courses have not
                only taught but also ingrained in me the language
                subtleties that actually hold great relevance
                within a professional environment. Now, I'm
                heading toward the preparations for my N2
                certification with confidence, knowing that the
                support and resources provided by Sahasra are
                second to none."

              </p>
            </div>
            <div className='flex justify-center items-center flex-col gap-6' >
              <Image
                alt='user1'
                src={images.user1}
                radius='full'
                width={250}
              />
              <p className='text-xl text-black  text-right'>
                Tirthankar Banerjee<br />
                Japanese Interpreter at Suzuki<br />
                N2 Aspirant<br />
              </p>
            </div>
          </div>
          <div className='md:w-[980px] w-full bg-opacity-70 p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:ml-10 md:mr-10'>
            <div className='flex justify-center items-center flex-col gap-6'>
              <Image
                alt='user1'
                src={images.user3}
                radius='full'
                width={250}
              />
              <p className='text-xl text-black'>
                Chitra Chatterjee<br />
                Japanese Language Learner<br />
                Student<br />
                N2 Aspirant<br />
              </p>
            </div>
            <div className='flex justify-center items-center'>
              <p className='text-black text-lg italic'>
                "Joining Sahasra Education a couple of years ago
                has been a transformative experience for me.
                The dedicated and supportive mentors have
                played a crucial role in my journey to achieving
                N5, N4 and N3 certificates one by one without a
                single failure. The course structure and
                interactive learning environment provided me
                the exact skills and confidence I needed to
                succeed. I'm grateful for the growth and
                opportunities I've experienced here. Now waiting
                for N2 course.."
              </p>
            </div>
          </div>

          <div className='md:w-[980px]  bg-opacity-70 p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:ml-10 md:mr-10'>
            <div className='flex justify-center items-center'>
              <p className='text-black text-lg italic'>
                "I'm pursuing B. A in Japanese language at
                Visva-Bharati University. Learning a language is not
                just about learning the language structures and all. It also
                about developing love for the culture, history
                and tradition as well. Being here, my perspective
                of learning the language has completely changed."
              </p>
            </div>
            <div className='flex justify-center items-center flex-col gap-6'>
              <Image
                alt='user1'
                src={images.user4}
                radius='full'
                width={250}
              />
              <p className='text-xl text-black md:text-right text-left mr-16'>
                Mohona Dhar<br />
                Pursuing Honours in Japanese Language & Literature<br />
                Visva-Bharati University<br />
                N3 Aspirant<br />
              </p>
            </div>
          </div>

        </div>
      </div>
      <ClassRoom />
      <Footer />
    </div>
  )
}

export default Home;
